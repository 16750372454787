<template>
  <div>
    <div v-if="$store.state.user.role == 'ADMIN'">
      <AdminPage />
    </div>
    <div v-else-if="$store.state.user.role == 'SUPPLIER'">
      <SupplierPage />
    </div>
    <div v-else-if="!$store.state.user.role || $store.state.user.role == 'STOREKEEPER'">
      <StorekeeperPage />
    </div>
  </div>
</template>

<script>
import AdminPage from "@/components/admin/Admin.vue";
import SupplierPage from "@/components/supplier/Supplier.vue";
import StorekeeperPage from "@/components/storekeeper/Storekeeper.vue";

export default {
  components: {
    AdminPage,
    SupplierPage,
    StorekeeperPage
  }
};
</script>
