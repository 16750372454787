<template>
  <SupplierStatistics></SupplierStatistics>
</template>
<script>
import SupplierStatistics from "./SupplierStatistics.vue";

export default {
  name: "Supplier",
  components: {
    SupplierStatistics
  }
};
</script>