<template>
  <div>
    <!-- <banner v-if="!hideBanners" /> -->
    <div class="container">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        :opacity="0.97"
        :z-index="2147480000"
      ></loading>

      <div
        class="fx fx-wrap main-product-flex fx-center"
        v-if="categoryTree && categoryTree.length"
      >
        <div v-for="category in categoryTree" :key="category.id" class="p">
          <router-link :to="computeLink(category)" class="container-product">
            <div class="fx fx-center fx-direct-cont clickable p-block">
              <div
                class="prod-img-block fx-center"
                v-if="category.imageUrl != null"
              >
                <img :src="category.imageUrl" alt />
              </div>
              <div v-else>
                <img
                  :src="baseUrl + 'img/logo.png'"
                  :alt="app_name + ' logo'"
                />
              </div>
            </div>
            <div class="fx-center txt-align-center cut-text p-name">
              <h2>{{ category.name }}</h2>
            </div>
          </router-link>
        </div>

 <!--       <div class="fx fx-main wd-100 fx-wrap fx-start">
          <h1> {{ categoryTree[0].parent.name }} </h1> 

          <router-link :to="computeLink(categoryTree[0])">
            <h1>{{ categoryTree[0].parent.name }}</h1>
          </router-link>

          <div v-for="category in categoryTree" :key="category.id" class='wd-100 fx-start fx'>
            <router-link :to="computeLink(category)">
              <h2>{{ category.name }}</h2>
            </router-link>
          </div>
        </div>
        -->
      </div>

      <div
        class="fx fx-center fx-start fx-wrap container"
        v-else-if="!isLoading"
      >
        <router-link :to="{ name: 'products' }" class="btn clickable"
          >Confira toda a Loja</router-link
        >
      </div>
      <div class="fixed-buttons fixed container">
        <a
          class="p-detail-btn clickable"
          @click="
            $router.zhhasBefore
              ? $router.go(-1)
              : $router.push({ name: 'home' })
          "
        >
          <i class="fa fa-angle-left"></i>
          Voltar
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.c {
  padding: 1em;
  background: #fff;
}
.c img {
  max-width: 100%;
  max-height: 100%;
}

.main-product-flex .p {
  box-shadow: 0 0 0 0 !important;
}
</style>

<script>
import router from "@/router";
import Category from "@/services/categories";
import BannerComponent from "@/components/common/BannerComponent.vue";
import boolean from "boolean";
import Loading from "vue-loading-overlay";

export default {
  name: "CategoryList",
  props: {
    root_category: String,
  },
  data: function () {
    return {
      categoryTree: [],
      isLoading: false,
      // hideBanners: false,
    };
  },
  beforeMount() {
    let rootCategory = null;
    if (typeof this.root_category !== "undefined") {
      rootCategory = { name: this.root_category };
    }
    this.listCategories(rootCategory);
    this.$root.$emit("openSearch");
  },
  methods: {
    computeLink: function (category) {
      return !category.children || category.children.length === 0
        ? this.productRoute(category)
        : this.categoryRoute(category);
    },
    productRoute: function (category) {
      return {
        name: "products",
        query: {
          search_categories: category.name,
        },
      };
    },
    categoryRoute: function (category) {
      return {
        name: "categories",
        query: {
          root_category: category ? category.name : null,
        },
      };
    },
    listCategories: function (root = null) {
      this.isLoading = true;
      Category.getAsTree(null, root)
        .then((categoryTree) => {
          if (!categoryTree || categoryTree.length === 0) {
            router.push(this.productRoute(root));
            return;
          }
          this.categoryTree = categoryTree;
          this.$forceUpdate();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  components: {
    Loading,
    banner: BannerComponent,
  },
};
</script>