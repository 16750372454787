<template>
  <div>
    <ManageUsers></ManageUsers>
  </div>
</template>

<script>
import ManageUsers from "./ManageUsers.vue";

export default {
  name: "Admin",
  components: {
    ManageUsers
  }
};
</script>