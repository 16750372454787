<template>
  <div class="container">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.97"
      :z-index="2147480000"
    ></loading>

    <div class="mg-top-0 cart-checkout-options wd-100 fx fx-direct-cont">
      <div>
        <span class="txt-align-center mg-0">Relatório de Desempenho</span>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-50 mt-3">
            <div>
              <button type="button" class="clickable btn mb-1" v-on:click="today()">Hoje</button> <br>
              <button type="button" class="clickable btn  mb-1" v-on:click="week()">Semanal</button> <br>
              <button type="button" class="clickable btn mb-1" v-on:click="mounth()">Mensal</button> <br>
              <button type="button" class="clickable btn" v-on:click="allTime()">Total de Vendas</button>
            </div>  
        </div> 
        <div class="col mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title txt-align-center mt-4">Venda Total</h5>
              <p class="card-text txt-align-center mb-4"> {{ this.totalSales * 100 | toCurrency}} </p>
            </div>
          </div>    
        </div>  
         <div class="col mt-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title txt-align-center mt-4">Custo Frete</h5>
              <p class="card-text txt-align-center mb-4"> {{ this.totalShipping * 100 | toCurrency }} </p>
            </div>
          </div>    
        </div>  
       <div class="col mt-3" v-if="this.salesOrders != 0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title txt-align-center mt-4">Ticket Médio</h5>
              <p class="card-text txt-align-center mb-4"> {{ ( (this.totalSales - this.totalShipping ) / this.salesOrders ) * 100 | toCurrency }} </p>
            </div>
          </div>    
        </div>  
        <div class="col mt-3" v-if="this.salesOrders === 0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title txt-align-center mt-4">Ticket Médio</h5>
              <p class="card-text txt-align-center mb-4"> {{ 0 | toCurrency }} </p>
            </div>
          </div>    
        </div>  
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h6 class="txt-align-center mg-0 main-product-title">Data Inicial</h6>
              <div class="txt-align-center mg-0" >
                <input type="date" name="init_date" id="init_date" v-model="searchParameters.init_date">
              </div>   
              <h6 class="txt-align-center mg-0 main-product-title">Data Final</h6>
              <div class="txt-align-center mg-0" >
                <input type="date" name="end_date" id="end_date" v-model="searchParameters.end_date">
              </div>   
              <div class="txt-align-center mt-1" >
                <button class="clickable btn" v-on:click="refreshDate()"> 
                  <i class="fas fa-sync"></i>
                </button>
              </div>   
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      
      <div class="line-chart-medium mg-2-top">
        <line-chart
          v-if="ordersByDate"
          :css-classes="'line-chart-inner'"
          :chart-data="ordersByDate"
          :options="ordersByDateOptions"
        />
      </div>
    </div>
    <div>
       <table class="table table-bordered mt-3">
            <thead>
              <tr>
                <th>Data</th>
                <th>Reais Pagos</th>
                <th>Valor Frete</th>
                <th>Pedidos Pagos</th>
                <th>Ticket médio</th>
                <th>Pedidos Pendentes/Cancelados</th>
                <th>Reais Pendentes/Cancelados</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in this.lineReport" :key="item">  
                <td >
                  {{ item.date }}
                </td>
                <td >
                  {{ item.value1 * 100 | toCurrency }}
                </td>
                <td >
                  {{ item.value5 * 100 | toCurrency }}
                </td>
                <td>
                  {{ item.value2 }}
                </td>
                <td v-if="item.value2 === 0 ">
                  {{ 0 | toCurrency}}
                </td>
                <td v-else>
                  {{ ( (item.value1 * 100) - (item.value5 * 100) ) / item.value2 | toCurrency}}
                </td>
                <td>
                  {{ item.value3 }}
                </td>
                <td >
                  {{ item.value4  * 100 | toCurrency }}
                </td>
              </tr>
            </tbody>
          </table>
    </div>
  </div>
</template>

<script>
import Order from "@/services/order.js";
import LineChart from "./LineChart.js";
import moment from "moment";
import Loading from "vue-loading-overlay";

var currentDate = new Date();
// taking the first day of the current month 
  var dayInit = "01";
  var month = String(currentDate.getMonth() + 1).padStart(2, '0');
  var year = currentDate.getFullYear();
  //adjust mongodb pattern 
    var dateInit = year + '-' + month + '-' + dayInit; 

//taking the last day of the current month
  var finalDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  var dayEnd =  String(finalDate.getDate()).padStart(2, '0');
  //adjust mongodb pattern 
    var dateEnd = year + '-' + month + '-' + dayEnd;  

//taking the current date
  var dayToday = String(currentDate.getDate()).padStart(2, '0');
  var monthToday = String(currentDate.getMonth() + 1).padStart(2, '0');
  var yearToday = currentDate.getFullYear();
  //date Today - adjust mongodb pattern 
    var dateToday = yearToday + '-' + monthToday + '-' + dayToday; 

//taking the week from the current date 
  var currentWeekDay = currentDate.getDay();
  var lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1;
  var wkStart = new Date(new Date(currentDate).setDate(currentDate.getDate() - lessDays));
  var wkEnd = new Date(new Date(wkStart).setDate(wkStart.getDate() + 6));
  //Week Start - adjust mongodb pattern 
    var weekStartDay = String(wkStart.getDate()).padStart(2, '0');
    var weekStartMounth = String(wkStart.getMonth() + 1).padStart(2, '0');
    var weekStartYear = wkStart.getFullYear();
    var weekStart = weekStartYear + '-' + weekStartMounth + '-' + weekStartDay; 
  //Week End - adjust mongodb pattern 
    var weekEndDay = String(wkEnd.getDate()).padStart(2, '0');
    var weekEndMounth = String(wkEnd.getMonth() + 1).padStart(2, '0');
    var weekEndYear = wkEnd.getFullYear();
    var weekEnd = weekEndYear + '-' + weekEndMounth + '-' + weekEndDay; 

export default {
  data() {
    return {
      loadIndex: 0,
      rawOrders: [],
      totalSales: 0,
      totalShipping: 0,
      salesOrders: 0,
      lineReport: [],
      tenant: process.env.VUE_APP_TENANT_ID,
      ordersByDate: null,
      ordersByDateOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              id: "Quantidade",
              type: "linear",
              position: "left",
              ticks: {
                beginAtZero: true,
                callback: v => v % 1 === 0 ? v : undefined,
              }
            },
            {
              id: "Reais",
              type: "linear",
              position: "right",
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      },
      searchParameters: {
        page: 0,
        perPage: 9999,
        init_date: dateInit,
        end_date: dateEnd,
      },
      period: "",
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    async fillData(period) {
      if(period == "Hoje"){
        this.searchParameters.init_date = dateToday;
        this.searchParameters.end_date = dateToday;
      }
      if(this.period == "Semanal"){
        this.searchParameters.init_date = weekStart;
        this.searchParameters.end_date = weekEnd;
      }
      if(this.period == "Mensal"){
        this.searchParameters.init_date = dateInit;
        this.searchParameters.end_date = dateEnd;
      }
      if(this.period == "allTime"){
        this.searchParameters.init_date = "2000-01-01";
        this.searchParameters.end_date = dateToday;
      }
      this.loadIndex++;
      this.rawOrders = await Order.list(this.searchParameters).finally(() => {
        this.loadIndex--;
      });
      const paidOrders = this.rawOrders.filter(o =>
        ["PAID", "ON_COURSE", "COMPLETED"].includes(o.status)
      );
      const nonPaidOrders = this.rawOrders.filter(
        o => !["PAID", "ON_COURSE", "COMPLETED"].includes(o.status)
      );
      const groupedPaidOrders = this.groupOrdersByDate(paidOrders);
      const groupedNonPaidOrders = this.groupOrdersByDate(nonPaidOrders);
      const orderedDates = [
        ...new Set(
          Object.keys(groupedPaidOrders)
            .concat(Object.keys(groupedNonPaidOrders))
            .sort(
              (a, b) =>
                moment(a).format("YYYYMMDD") - moment(b).format("YYYYMMDD")
            )
        )
      ];
      this.ordersByDate = {
        labels: orderedDates.map(date => moment(date).format("DD/MM/YYYY")),
        datasets: [
          {
            label: "Reais Pagos",
            yAxisID: "Reais",
            backgroundColor: "rgba(34, 170, 34, 0.5)",
            pointBackgroundColor: 'rgba(34, 170, 34, 0.5)',
            pointHoverBackgroundColor: 'rgba(34, 170, 34, 0.5)',
            borderColor: 'rgba(34, 170, 34, 0.5)',
            pointBorderColor: 'rgba(34, 170, 34, 0.5)',
            pointHoverBorderColor: 'rgba(34, 170, 34, 0.5)',
            data: orderedDates.map(date =>
              (groupedPaidOrders[date] || [])
                .map(o => o.value)
                .reduce((v1, v2) => v1 + v2, 0)
            ),
            data2: orderedDates.map(date =>
              (groupedPaidOrders[date] || [])
                .map(o => o.value2)
                .reduce((v1, v2) => v1 + v2, 0)
            ),
          },
          {
            label: "Pedidos Pagos",
            yAxisID: "Quantidade",
            backgroundColor: "rgba(170, 255, 170, 0.5)",
            pointBackgroundColor: 'rgba(170, 255, 170, 0.5)',
            pointHoverBackgroundColor: 'rgba(170, 255, 170, 0.5)',
            borderColor: 'rgba(170, 255, 170, 0.5)',
            pointBorderColor: 'rgba(170, 255, 170, 0.5)',
            pointHoverBorderColor: 'rgba(170, 255, 170, 0.5)',
            data: orderedDates.map(
              date => (groupedPaidOrders[date] || []).length
            ),
          },
          {
            label: "Pedidos Pendentes/Cancelados",
            yAxisID: "Quantidade",
            backgroundColor: "rgba(255, 200, 0, 0.5)",
            pointBackgroundColor: 'rgba(255, 200, 0, 0.5)',
            pointHoverBackgroundColor: 'rgba(255, 200, 0, 0.5)',
            borderColor: 'rgba(255, 200, 0, 0.5)',
            pointBorderColor: 'rgba(255, 200, 0, 0.5)',
            pointHoverBorderColor: 'rgba(255, 200, 0, 0.5)',
            data: orderedDates.map(
              date => (groupedNonPaidOrders[date] || []).length
            ),
          },
          {
            label: "Reais Pendentes/Cancelados",
            yAxisID: "Reais",
            backgroundColor: "rgba(255, 170, 170, 0.5)",
            pointBackgroundColor: 'rgba(255, 170, 170, 0.5)',
            pointHoverBackgroundColor: 'rgba(255, 170, 170, 0.5)',
            borderColor: 'rgba(255, 170, 170, 0.5)',
            pointBorderColor: 'rgba(255, 170, 170, 0.5)',
            pointHoverBorderColor: 'rgba(255, 170, 170, 0.5)',
            data: orderedDates.map(date =>
              (groupedNonPaidOrders[date] || [])
                .map(o => o.value)
                .reduce((v1, v2) => v1 + v2, 0)
            ),
            data2: orderedDates.map(date =>
              (groupedPaidOrders[date] || [])
                .map(o => o.value2)
                .reduce((v1, v2) => v1 + v2, 0)
            ),
          }
        ]
      };
      this.salesReport(this.ordersByDate);     
    },
    today(){
      this.period = "Hoje";
      this.fillData(this.period);
    },
    week(){
      this.period = "Semanal";
      this.fillData(this.period);
    },
    mounth(){
      this.period = "Mensal";
      this.fillData(this.period);
    },
    refreshDate(){
      this.period = "";
      this.fillData(this.period);
    },
    allTime(){
      this.period = "allTime";
      this.fillData(this.period);
    },
    groupOrdersByDate(orders) {
      const mappedOrders = (orders || []).map(this.mapOrder);
      const groupedOrders = mappedOrders.reduce((p, o) => {
        const dateGroup = p[o.date] || [];
        dateGroup.push(o);
        p[o.date] = dateGroup;
        return p;
      }, {});
      return groupedOrders;
    },
    mapOrder(order) {
      if(this.tenant === "bazpet"){
         return {
          date: moment(order.created_at).format("YYYY-MM-DD"),
          value: order.full_amount_statistics / 100,
          value2: order.shipping_amount_statistics / 100
        }
      }else{
          let full_amount = order.full_amount;
          if (order.enabled_plotted) {
              full_amount = parseInt(order.full_amount) + parseInt(order.plotted_amount);
          }
         return {
          date: moment(order.created_at).format("YYYY-MM-DD"),
          value: full_amount / 100,
          value2: order.shipping_amount / 100
        };
      }
    },
    salesReport(ordersByDate){
      this.lineReport = [];
      let t = 0;
      ordersByDate['labels'].forEach(element => {     
        let aux = element.split('/');
        if(t==0 && this.period == "allTime"){
          this.searchParameters.init_date = aux[2] + "-" + aux[1] + "-" + aux[0];
        }
        this.lineReport.push({date: element, value1: '0', value2: '0', value3: '0',value4: '0', value5: '0'});
        t++;
      });
      this.lineReport.push({date: "Total", value1: '0', value2: '0', value3: '0',value4: '0', value5: '0'});
      ordersByDate['datasets'].forEach(element => {
        if(element.label === "Reais Pagos"){
          let orderAmount = 0;
          let shippingAmount = 0;
          for (let x = 0; x < element.data.length; x++) {
            this.lineReport[x]['value1'] = element.data[x];
            orderAmount = orderAmount + element.data[x];
          }
          for (let x = 0; x < element.data2.length; x++) {
            this.lineReport[x]['value5'] = element.data2[x];
            shippingAmount = shippingAmount + element.data2[x];
          }
          this.lineReport[t]['value1'] = orderAmount;
          this.lineReport[t]['value5'] = shippingAmount;
          this.totalSales = orderAmount;
          this.totalShipping = shippingAmount;
          
        }
        if(element.label === "Pedidos Pagos"){
          let orderAmount = 0;
          for (let x = 0; x < element.data.length; x++) {
            this.lineReport[x]['value2'] = element.data[x];
            orderAmount = orderAmount + element.data[x];
          }
          this.lineReport[t]['value2'] = orderAmount;
          this.salesOrders = orderAmount;
        }
        if(element.label === "Pedidos Pendentes/Cancelados"){
          let orderAmount = 0;
          for (let x = 0; x < element.data.length; x++) {
            this.lineReport[x]['value3'] = element.data[x];
            orderAmount = orderAmount + element.data[x];
          }
          this.lineReport[t]['value3'] = orderAmount;
        }
        if(element.label === "Reais Pendentes/Cancelados"){
          let orderAmount = 0;
          for (let x = 0; x < element.data.length; x++) {
            this.lineReport[x]['value4'] = element.data[x];
            orderAmount = orderAmount + element.data[x];
          }
         this.lineReport[t]['value4'] = orderAmount;
        }
      });
    }
  },
  components: {
    LineChart,
    Loading
  },
  computed: {
    isLoading: function() {
      return this.loadIndex > 0;
    }
  }
};
</script>

<style scoped>
.h2 {
  text-align: center;
}
.line-chart-medium {
  height: 400px;
  position: relative;
  width: 100%;
}
.line-chart-inner {
  height: 100%;
  position: relative;
  width: 100%;
}
input[type=date] {
  border-radius: 0.5em;
} 
</style>
