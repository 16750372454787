<template>
  <div>
    <ProductListComponent v-if="!initCategories"></ProductListComponent>
    <CategoryListComponent v-else></CategoryListComponent>
  </div>
</template>
<script>
import ProductListComponent from "./ProductList.vue";
import CategoryListComponent from "./CategoryList.vue";
import boolean from "boolean";

export default {
  name: "Storekeeper",
  data: function() {
    return {
      initCategories: boolean(process.env.VUE_APP_INIT_CATEGORIES)
    };
  },
  components: {
    ProductListComponent,
    CategoryListComponent,
  }
};
</script>
<style scoped>

</style>
